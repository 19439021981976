import { css } from "@emotion/react";
import { Link, Text, theme, useColorMode, useColorStyle, Stack } from "@tonic-ui/react";
import { FormattedMessage } from "react-intl";
import useCommon from "@/hooks/useCommon";
import { PortalLink } from "@/types/enum";

export default function Footer() {
    const [colorMode] = useColorMode();
    const [colorStyle] = useColorStyle({ colorMode });
    const { color } = colorStyle;
    const linkMessage = useCommon();

    return (
        <footer>
            <Stack
                direction="row"
                w="100%"
                position="fixed"
                bottom={0}
                px={24}
                h={40}
                backgroundColor="gray:90"
                fontSize="sm"
                gap="6x"
                css={css`
                    a {
                        color: ${theme.colors[color.info]} !important;
                    }
                    `}
            >
                <Text
                    color="white:secondary"
                    display="flex"
                    alignItems="center"
                >
                    <FormattedMessage
                        defaultMessage="Copyright © {year} Trend Micro Incorporated. All rights
                        reserved."
                        values={{
                            year: `1999-${new Date().getFullYear()}`,
                        }}
                    />
                </Text>
                <Link
                    data-test="link_footer_tend"
                    data-track="link_activation_footer_trend_micro"
                    href={linkMessage[PortalLink.TrendMicro].link}
                    target="_blank"
                >
                    {linkMessage[PortalLink.TrendMicro].name}
                </Link>
                <Link
                    data-test="link_footer_legal_privacy"
                    data-track="link_activation_footer_legal_privacy"
                    href={linkMessage[PortalLink.LegalPoliciesPrivacy].link}
                    target="_blank"
                >
                    {linkMessage[PortalLink.LegalPoliciesPrivacy].name}
                </Link>
                <Link
                    data-test="link_footer_contact_us"
                    data-track="link_activation_footer_contact_us"
                    href={linkMessage[PortalLink.ContactUs].link}
                    target="_blank"
                >
                    {linkMessage[PortalLink.ContactUs].name}
                </Link>
                <Link
                    data-test="link_footer_help"
                    data-track="link_activation_footer_help"
                    href={linkMessage[PortalLink.Help].link}
                    target="_blank"
                >
                    {linkMessage[PortalLink.Help].name}
                </Link>
            </Stack>
        </footer>
    );
}
