import { Stack } from "@tonic-ui/react";
import { ReactComponent as LoadingImage } from "@/assets/svg/image_loading.svg";
import { css } from "@emotion/react";

const LoadingAnimation = () => {
    return (
        <Stack
            css={css`
                position: relative;
                padding: 0;
                .dot-loader,
                .dot-loader:before,
                .dot-loader:after {
                    border-radius: 50%;
                    width: 0.7em;
                    height: 0.7em;
                    background-color: transparent;
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                    -webkit-animation: load7 1.8s infinite ease-in-out;
                    animation: load7 1.8s infinite ease-in-out;
                }
                .dot-loader {
                    color: #ffffff;
                    font-size: 14px;
                    margin: 16px auto;
                    position: relative;
                    text-indent: -9999em;
                    -webkit-transform: translateZ(0);
                    -ms-transform: translateZ(0);
                    transform: translateZ(0);
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;
                }
                .dot-loader:before,
                .dot-loader:after {
                    content: "";
                    position: absolute;
                    top: 0;
                }
                .dot-loader:before {
                    left: -1em;
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                }
                .dot-loader:after {
                    left: 1em;
                }
                @-webkit-keyframes load7 {
                    0%,
                    80%,
                    100% {
                        box-shadow: 0 0.7em 0 -1.3em;
                    }
                    40% {
                        box-shadow: 0 0.7em 0 0;
                    }
                }
                @keyframes load7 {
                    0%,
                    80%,
                    100% {
                        box-shadow: 0 0.7em 0 -1.3em;
                    }
                    40% {
                        box-shadow: 0 0.7em 0 0;
                    }
                }
                .stack-top {
                    position: absolute;
                    bottom: 25px;
                    right: 88px;
                    transform: translateX(68px);
                }
                .loader-bg {
                    position: absolute;
                    bottom: 10px;
                    right: 35px;
                    transform: translateX(41px);
                    width: 60px;
                    height: 60px;
                    background-color: #578aef;
                    border-radius: 50%;
                }
            `}
        >
            <Stack>
                <LoadingImage />
                <div className="loader-bg"></div>
                <div className="stack-top" data-test="spinner_activating">
                    <div className="dot-loader"></div>
                </div>
            </Stack>
        </Stack>
    );
};

export default LoadingAnimation;
