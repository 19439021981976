import { css } from "@emotion/react";

export default css`
    /* Glob style setup */
    * {
        box-sizing: border-box;
        font-family: Segoe UI, -apple-system, BlinkMacSystemFont, Helvetica Neue,
            Helvetica, Arial, sans-serif;
    }

    body {
        font-size: 0.875rem;
        line-height: 0.875rem;
        background-color: #151515;
        color: rgba(255, 255, 255, 0.92);
        line-height: 1.25rem;
        font-size: 0.875rem;
        min-height: 100vh;
    }
    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }
    /* scrollbar */
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: hsla(0, 0%, 100%, 0.35);
        border: 1px solid hsla(0, 0%, 100%, 0.2);
    }
    ::-webkit-scrollbar-track {
        background-color: #222;
    }

    /* Utility Class */
    .dark-mode-img {
        background: url("./assets/img/bg_darkmode.png") no-repeat center center fixed !important;
        background: none !important;
        -webkit-background-size: cover !important;
        -moz-background-size: cover !important;
        -o-background-size: cover !important;
        background-size: cover !important;
    }

    .card-bg {
        background: #212121;
        border: 1px solid #303030;
    }
    .card-ft-bg {
        background: #303030;
        border: 1px solid #303030;
    }

    /* Utility Class */
    .u-cursor-pointer {
        cursor: pointer !important;
    }
    .u-link-color {
        color: #6f9bf4 !important;
    }
    .u-mb-3-1 {
        margin-bottom: 0.75rem !important;
    }

    /* toast container */
    div[data-toast-placement] {
        overflow: visible !important;
    }
`;
