import { combineReducers } from "redux";
import activation from "./activationReducer";

const rootReducer = combineReducers({
    activation,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
