import { HTMLProps } from "react";
import { Icon } from "@tonic-ui/react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";

interface ImportHintProps extends HTMLProps<HTMLDivElement> {
    importToolUrl: string;
}

const ImportHint = ({ importToolUrl, ...rest }: ImportHintProps) => {
    return importToolUrl ? (
        <div
            css={css`
                display: flex;
                justify-content: center;
            `}
            {...rest}
        >
            <Icon icon="info-o" size="4x" mr={4} mt={2} />
            <div>
                <FormattedMessage
                    defaultMessage="Proceed to the <a>import tool</a> to complete the process if you've already exported the entitlement file."
                    values={{
                        a: (txt: string) => (
                            <a
                                href={importToolUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {txt}
                            </a>
                        ),
                    }}
                />
            </div>
        </div>
    ) : null;
};

export default ImportHint;
