import { IntlProvider } from "react-intl";

function loadLocaleData(locale) {
    switch (locale) {
        case "en-US":
            return import("@root/compiled-lang/en/common_use.json");
        case "ja":
            return import("@root/compiled-lang/ja/common_use.json");
        default:
            return import("@root/compiled-lang/en/common_use.json");
    }
}

const defaultLocale = "en-US";
const locale = navigator.language || defaultLocale;

async function bootstrapIntl(Component) {
    const messages = await loadLocaleData(locale);
    return function WithIntl() {
        return (
            <IntlProvider
                messages={messages}
                locale={locale}
                defaultLocale={defaultLocale}
                defaultRichTextElements={{
                    br: () => <br />,
                    b: (txt) => <b>{txt}</b>,
                }}
            >
                <Component />
            </IntlProvider>
        );
    };
}

export default bootstrapIntl;
