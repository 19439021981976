import { css } from "@emotion/react";
import { Space, Spinner } from "@tonic-ui/react";

interface ILoadingProps {
    height?: string;
}
export default function Loading({ height = "10rem" }: ILoadingProps) {
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            `}
        >
            <Space height={height} />
            <Spinner lineWidth={2} trackWidth={2} size="lg" data-test="spinner" />
        </div>
    );
}
