import { HTMLAttributes, useCallback, useState } from "react";
import { css } from "@emotion/react";
import { Icon, Link } from "@tonic-ui/react";
import useToast from "@/hooks/useToast";

interface ErrorLogProps extends HTMLAttributes<HTMLDivElement> {
    title: string;
    log: string;
}
export default function ErrorLog({ log, title, ...rest }: ErrorLogProps) {
    const [extend, setExtend] = useState(false);
    const { notify } = useToast();
    const copyLog = useCallback(() => {
        const data = [
            new ClipboardItem({
                "text/plain": new Blob([log], { type: "text/plain" }),
            }),
        ];
        navigator.clipboard.write(data).then(
            function () {
                notify({
                    message: "Copied to clipboard",
                });
            },
            function () {
                console.error("Unable to write to clipboard. :-(");
            },
        );
    }, [log, notify]);
    return (
        <div
            css={css`
                text-align: left;
                padding: 8px;
                color: rgba(255, 255, 255, 0.6);
                background-color: #212121;
                white-space: ${extend ? "pre-line" : "nowrap"};
            `}
            {...rest}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    color: #faba2a;
                    padding-bottom: 8px;
                    font-size: 14px;
                `}
            >
                <Icon color="yellow:50" icon="warning-triangle" mr={8} />

                {title}
                <div
                    css={css`
                        flex-grow: 1;
                    `}
                ></div>
                <Link
                    onClick={() => {
                        setExtend((e) => !e);
                    }}
                    css={css`
                        margin-right: 8px;
                        text-decoration: underline;
                    `}
                >
                    {extend ? "collapse" : "extend all"}
                </Link>
                <Link
                    onClick={copyLog}
                    css={css`
                        margin-right: 8px;
                        text-decoration: underline;
                    `}
                >
                    copy
                </Link>
            </div>
            <div
                css={css`
                    overflow: auto;
                `}
            >
                {log}
            </div>
        </div>
    );
}
