import { PortalLink } from "@/types/enum";
import { FunctionComponent, useMemo } from "react";
import type { IntlFormatters } from "react-intl";
import { FormattedMessage, useIntl } from "react-intl";
import useCommon from "../useCommon";
import { Link, Text } from "@tonic-ui/react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { backToEnterAC } from "@/redux/actions/activationAction";
import { RootState } from "@/redux/reducers";
import { IActivationState } from "@/types/redux/activation";
import { FullyActivated } from "@/components/common/PreCheck";
import LoadingAnimation from "@/components/common/LoadingAnimation";
import clockImage from "@/assets/svg/image_clock.svg";

const Trend = ({ desc = true }) => {
    const common = useCommon();
    const trend = common[PortalLink.TrendMicroSupport];
    return desc ? (
        <FormattedMessage
            defaultMessage="To complete the activation, please contact <link></link>."
            values={{
                link: () => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        data-test="link_go_trend_contact_support"
                        data-track="link_activation_error_go_trend_contact_support"
                        className="u-link-color"
                        href={trend.link}
                    >
                        {trend.name}
                    </a>
                ),
            }}
        ></FormattedMessage>
    ) : (
        <a
            rel="noreferrer"
            target="_blank"
            data-test="link_go_trend_contact_support"
            data-track="link_activation_error_go_trend_contact_support"
            className="u-link-color"
            href={trend.link}
        >
            {trend.name}
        </a>
    );
};

const CLP = ({ desc = true }) => {
    const common = useCommon();
    const clp = common[PortalLink.CustomerLicensingPortal];
    return desc ? (
        <FormattedMessage
            defaultMessage="To access your Trend Micro products and services, go to <link></link>."
            values={{
                link: () => (
                    <a
                        rel="noreferrer"
                        target="_blank"
                        data-test="link_go_clp"
                        data-track="link_activation_error_go_clp"
                        className="u-link-color"
                        href={clp.link}
                    >
                        {clp.name}
                    </a>
                ),
            }}
        ></FormattedMessage>
    ) : (
        <a
            className="u-link-color"
            rel="noreferrer"
            target="_blank"
            data-test="link_go_clp"
            data-track="link_activation_error_go_clp"
            href={clp.link}
        >
            {clp.name}
        </a>
    );
};

export type ErrorCode =
    | "EXA0000001"
    | "EXA0000010"
    | "EXA0000011"
    | "EXA0000012"
    | "EXA0000013"
    | "EXA000001X"
    | "EXA000001Z"
    | "EXA0000031"
    | "EXA000003Z"
    | "EX90000001"
    | "EX90000002"
    | "EX90000003"
    | "EX90000004"
    | "EX90000011"
    | "EX90000012"
    | "EX9000001Z"
    | "EXA00000E1"
    | "EXF0000001"
    | "EXA0000045"
    | "EXA000005G"
    | "EXA00001B2"
    | "INTERNALERROR"
    | "C1_LICENSE_NOT_ELIGIBLE";

// IErrorSetting is the interface of error message setting
interface IErrorSetting {
    title: ReturnType<IntlFormatters["formatMessage"]>;
    description: ReturnType<IntlFormatters["formatMessage"]>;
    Contact?: FunctionComponent<{ desc?: boolean }>;
    changePage?: JSX.Element;
    image?: JSX.Element;
}

const useErrorMessage = (): Record<ErrorCode, IErrorSetting> => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const activation = useSelector(
        (data: RootState) => data.activation,
    ) as IActivationState;

    const alreadyActivated = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Products Already Activated",
            }),
            description: intl.formatMessage({
                defaultMessage: "Your Trend Micro solutions have already been activated.",
            }),
            changePage: <FullyActivated />,
        }),
        [intl],
    );

    const activationFail = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Unable to Complete Activation",
            }),
            description: null,
            Contact: Trend,
        }),
        [intl],
    );
    const activationExpired = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Activation Link Expired",
            }),
            description: intl.formatMessage(
                {
                    defaultMessage:
                        "The activation link has expired. To view your Trend Micro products and services, go to <br></br> <contact></contact>.",
                },
                {
                    contact: () => <CLP desc={false} />,
                },
            ),
        }),
        [intl],
    );
    const invalidActivationLink = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Invalid Activation Link",
            }),
            description: intl.formatMessage(
                {
                    defaultMessage:
                        "Check your mailbox for a new activation link or contact <contact></contact>.",
                },
                {
                    contact: () => <Trend desc={false} />,
                },
            ),
        }),
        [intl],
    );
    const insufficientPermissions = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Insufficient permissions",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "Please sign in using an account that has the correct permissions and try again. Contact your system administrator for more information.",
            }),
        }),
        [intl],
    );
    const notEligibleForComplimentaryCredit = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Not eligible for complimentary credits",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "You are currently not eligible for complimentary credits.<br></br> For more information, contact your sales representative or support provider.",
            }),
        }),
        [intl],
    );
    const gLinkC1AccountAlreadyMapped = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Activation Failed",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "This Primary User Account is already associated with a different Trend Cloud One account. To activate Trend Vision One, you can restart the process, use the same API key, and sign in with a different Primary User Account.",
            }),
        }),
        [intl],
    );
    const tokenIncompleteByMarketplace = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Account setup not yet available",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "Allow a few minutes for the license data synchronization to complete. Please try again later.",
            }),
            image: <LoadingAnimation />,
        }),
        [intl],
    );
    const gLinkInternalError = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "An internal error has occurred.",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "Please try again later. If the issue persists, contact your support provider.",
            }),
        }),
        [intl],
    );
    const gLinkC1LicenseNotEligible = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Activation Failed",
            }),
            description: intl.formatMessage(
                {
                    defaultMessage:
                        "Ensure that your license is up-to-date, and try again. Trial licenses are not eligible.<br></br>You can try to activate using your Trend Cloud One Activation Code by selecting <onprem></onprem> from the drop-down menu of <back></back>.<br></br>If the issue persists, please contact your support representative.",
                },
                {
                    onprem: () => (
                        <Text display="inline-block" fontWeight="bold">
                            <FormattedMessage defaultMessage="On-premises product"></FormattedMessage>
                        </Text>
                    ),
                    back: () => (
                        <Link
                            fontWeight="bold"
                            onClick={() => {
                                dispatch(backToEnterAC());
                                activation.token
                                    ? history.push("/g1")
                                    : window.location.replace("/g1");
                            }}
                        >
                            <FormattedMessage defaultMessage="Activating Trend Vision One"></FormattedMessage>
                        </Link>
                    ),
                },
            ),
        }),
        [intl, dispatch, history, activation.token],
    );
    const anotherRootAccountExists = useMemo(
        () => ({
            title: intl.formatMessage({
                defaultMessage: "Upgrade in Progress",
            }),
            description: intl.formatMessage({
                defaultMessage:
                    "Another admin already started the upgrade to Trend Vision One for Service Providers.",
            }),
            image: <img src={clockImage} alt="Not Support" />,
        }),
        [intl],
    );

    return {
        EXA0000001: {
            title: "",
            description: "",
            Contact: Trend,
        },
        EXA0000010: invalidActivationLink,
        EXA0000011: invalidActivationLink,
        EXA0000012: activationExpired,
        EXA0000013: invalidActivationLink,
        EXA000001X: insufficientPermissions,
        EXA000001Z: alreadyActivated,
        EXA0000031: activationExpired,
        EXA000003Z: alreadyActivated,
        EX90000001: activationFail,
        EX90000002: activationFail,
        EX90000003: invalidActivationLink,
        EX90000004: invalidActivationLink,
        EX90000011: activationFail,
        EX90000012: activationFail,
        EX9000001Z: alreadyActivated,
        EXA00000E1: notEligibleForComplimentaryCredit,
        EXF0000001: notEligibleForComplimentaryCredit,
        EXA0000045: gLinkC1AccountAlreadyMapped,
        EXA000005G: tokenIncompleteByMarketplace,
        EXA00001B2: anotherRootAccountExists,
        INTERNALERROR: gLinkInternalError,
        C1_LICENSE_NOT_ELIGIBLE: gLinkC1LicenseNotEligible,
    };
};

export default useErrorMessage;
