import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// can be used in components, ex: mapStateToProps
export type RootStore = ReturnType<typeof rootReducer>;

export default store;
