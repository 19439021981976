import Body from "@/components/layout/Body";
import { Component, ErrorInfo } from "react";
import { Container, Row, Col } from "reactstrap";
import warningImage from "@/assets/svg/image_warning.svg";
import { css } from "@emotion/react";

class ErrorBoundary<P extends object> extends Component<P, { error: Error | null }> {
    constructor(props: P) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        this.setState({
            error,
        });
    }

    render() {
        if (this.state.error) {
            return (
                <Body>
                    <Container className="text-center ">
                        <Row
                            css={css`
                                padding: 64px 0 0 0;
                            `}>
                            <Col>
                                <img src={warningImage} alt="Not Support" />
                            </Col>
                        </Row>
                        <div
                            className="pt-3"
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                font-size: 20px;
                                small {
                                    margin-top: 20px;
                                    opacity: 0.6;
                                    font-size: 12px;
                                }
                            `}>
                            Unexpected error
                            <small>{this.state.error.message || "unknown error"}</small>
                        </div>
                    </Container>
                </Body>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
