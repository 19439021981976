import { ActivationType } from "@/types/enum";
import { IGLinkPayload } from "@/types/redux/activation";

export const defaultGLinkPayload: IGLinkPayload = {
    ac: "",
    apiKey: "",
    productId: "",
    versionType: "",
    expirationDate: new Date(),
    isEligible: false,
    isRegistered: false,
    hasAccount: false,
    errorCode: "",
    errorMessage: "",
    isJp: false,
    accounts: [],
    activationType: ActivationType.ActivationCode,
    entitlementId: "",
    effectiveDate: null,
    sourceChannel: "",
    subscriptionId: "",
    c1AccountId: "",
    companyId: "",
};
