/* eslint-disable camelcase */
import { postUnknowUserUBTLog, postUserUBTLog } from "@/api/ubt";
import { link_activation_result_type1 } from "./constants";
import { IPostUserUBTLogBody } from "@/types/api/ubt";
import { ActivationType } from "@/types/enum";

export interface IGenericLinkUBTPayload {
    ac?: string;
    pid?: string;
    accountId?: string;
    errorCode?: string;
    ticket?: string;
    activationType?: ActivationType;
    entitlementId?: string;
    c1AccountId?: string;
    sourceChannel?: string;
}

export const session = { sessionId: "" };

export const setUBTSessionId = (s: string) => {
    session.sessionId = s;
};

export const UBTLoggingService = (
    category: string,
    activity: string,
    result: string,
    payload: IGenericLinkUBTPayload,
) => {
    const element = [category, activity, result].filter((e) => e);
    const eventName = element.join("_").toLowerCase();
    const p: { [name: string]: string | null } = {
        ...payload,
        ...session,
    };

    if (category === "Glink") {
        const body: IPostUserUBTLogBody = {
            eventName,
            activity: "",
            errorCode: p.errorCode?.toString() ?? null,
            loginId: p.accountId,
            attributes: removeDuplicateAttribute(p) as IGenericLinkUBTPayload,
        };
        postUserUBTLog(body);
        // TODO call glink ubt api
    } else {
        // TODO call ubt mapper
        ubtLoggerMapper(eventName);
    }
};

const removeDuplicateAttribute = (obj: { [name: string]: string | null }) => {
    const tmp = { ...obj };
    delete tmp.errorCode;
    delete tmp.accountId;
    Object.keys(tmp).forEach((k) => !tmp[k] && delete tmp[k]);
    return tmp;
};

const ubtLoggerMapper = (eventName: string) => {
    switch (eventName) {
        case link_activation_result_type1:
            return postUnknowUserUBTLog({
                eventName: link_activation_result_type1,
                activity: "1",
            });

        default:
            return null;
    }
};
