import { getFeatureFlag } from "@/api/site";
import { ComponentType, useContext, useLayoutEffect } from "react";
import useGoErrorPage from "@/hooks/error/useGoErrorPage";
import { frontendConfigContext } from "@/Wrapper/FrontendConfig";

function withCheckFeatureFlag<P extends object>(
    Component: ComponentType<P>,
    featureEnableFn: (feature: Awaited<ReturnType<typeof getFeatureFlag>>) => boolean,
) {
    return function FeatureFlagComponent(props: P) {
        // feature flag
        const { featureflags } = useContext(frontendConfigContext);
        const goErrorPage = useGoErrorPage();
        useLayoutEffect(() => {
            if (!featureEnableFn(featureflags)) goErrorPage();
        }, [featureflags, goErrorPage]);

        return <Component {...props} />;
    };
}

export default withCheckFeatureFlag;
