import { StrictMode } from "react";
import ReactDOM from "react-dom";
import bootstrapIntl from "./Hoc/bootstrapIntl";
import { Global } from "@emotion/react";
import App from "./App";
import globalStyle from "./globalStyle";
import "bootstrap/dist/css/bootstrap.min.css";

bootstrapIntl(App).then((AppWithIntl) => {
    ReactDOM.render(
        <StrictMode>
            <Global styles={globalStyle}></Global>
            <AppWithIntl />
        </StrictMode>,
        document.getElementById("root"),
    );
});
