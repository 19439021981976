import { CSSBaseline } from "@tonic-ui/react";
import { Provider } from "react-redux";
import store from "@/redux/store";
import Routes from "@/routes";
import TrendUITheme from "./Wrapper/TrendUITheme";
import FrontendConfig from "@/Wrapper/FrontendConfig";
import ReCaptchaV3Provider from "./Wrapper/ReCaptchaV3Provider";

const App = () => {
    return (
        <Provider store={store}>
            <TrendUITheme>
                <CSSBaseline />
                <FrontendConfig>
                    <ReCaptchaV3Provider>
                        <Routes />
                    </ReCaptchaV3Provider>
                </FrontendConfig>
            </TrendUITheme>
        </Provider>
    );
};

export default App;
