import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IPurchasedItem, ITrialItem } from "@/types/business/order";
import { ActivationPreviewTag } from "@/types/enum";
import { IGetActivationPreviewResultAsyncResponse } from "@/types/api/activation";

export const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";
// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || !!document.documentMode;
// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;
// Chrome
export const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;

type AxiosMethods = "get" | "delete" | "head" | "options" | "post" | "put" | "patch";

export const axiosInstanceWithAdditionHeaders = (
    instance: AxiosInstance,
    addHeaders: Record<string, string> | (() => Record<string, string>),
): AxiosInstance =>
    new Proxy(instance, {
        get(target, name: AxiosMethods) {
            return typeof target[name] === "function"
                ? (
                      url: string,
                      ...rest: [
                          data?: Record<string, unknown>,
                          config?: AxiosRequestConfig,
                      ]
                  ) => {
                      const hasData = ["post", "put", "patch"].includes(name);
                      const additionalHeaders =
                          typeof addHeaders === "function" ? addHeaders() : addHeaders;
                      const data = hasData ? [rest.shift()] : [];
                      const config = rest.shift() || {};
                      config.headers = Object.assign(
                          additionalHeaders,
                          config.headers || {},
                      );
                      return target[name](url, ...[...data, config]);
                  }
                : target[name];
        },
    });

export const isCredit = (purchaseItem?: IPurchasedItem | ITrialItem) =>
    purchaseItem?.serviceName === "Credit";

export const filterPreviewData = ({
    data,
    isCloudOne = false,
    isVoucher = false,
}: {
    data: IGetActivationPreviewResultAsyncResponse | null;
    isCloudOne: boolean;
    isVoucher?: boolean;
}): IGetActivationPreviewResultAsyncResponse | null => {
    if (!data) return null;
    if (isCloudOne && isVoucher) return data;

    const filteredItem = (
        items: IPurchasedItem[] | null | undefined,
    ): IPurchasedItem[] | null | undefined =>
        items &&
        items.filter((item) =>
            isCloudOne
                ? item?.activationPreviewTag === ActivationPreviewTag.Preview_CloudOne ||
                  item?.activationPreviewTag ===
                      ActivationPreviewTag.Preview_CloudOneWithVisionOne
                : item?.activationPreviewTag !== ActivationPreviewTag.Preview_CloudOne,
        );

    return {
        ...data,
        purchasedItems: filteredItem(data.purchasedItems),
        trialItems: isCloudOne ? null : data.trialItems,
        convertedCredits: isCloudOne ? null : data.convertedCredits,
    };
};

export const handleTrialItems = (
    data: IGetActivationPreviewResultAsyncResponse | null,
) => {
    if (!data) return null;
    /**
     * Handle display name of general (which is 0 complementary credit) in preview page and activate success email
     */
    return {
        ...data,
        trialItems: data?.trialItems?.map((t) => {
            if (t.amount === 0 && isCredit(t)) {
                return {
                    ...t,
                    serviceName: "Advanced Trend Vision One Trial",
                };
            }
            return t;
        }),
    };
};

export const retryPromise = <Res>(
    fn: () => Promise<Res>,
    detectFn: (data: Res) => boolean,
    resolveFn: (data: Res) => Res,
    options: {
        delayTime: number;
        limitTime: number;
    },
): Promise<Res> => {
    const { delayTime, limitTime } = options;
    let count = 0;

    const execute = (): Promise<Res> =>
        fn()
            .then(async (resp) => {
                count += delayTime;

                if (detectFn(resp)) return Promise.resolve(resolveFn(resp));
                if (count < limitTime) return delay(delayTime).then(() => execute());

                return Promise.reject(new Error("Exceed maximum retry limit."));
            })
            .catch((err: Error) => Promise.reject(err));

    return execute();
};

export const combineURLPath = (...urls: (string | undefined)[]): string =>
    urls.join("/").replace(/\/+/g, "/");

const defaultLanguage = "en-US";

export const getUICLang = () => {
    const lang =
        localStorage.getItem("uic_language") || navigator.language || defaultLanguage;
    return lang === "ja" ? "ja-JP" : lang;
};
