import { TonicProvider, ToastProvider } from "@tonic-ui/react";
import { FunctionComponent } from "react";

const TrendUITheme: FunctionComponent = (props) => {
    return (
        <TonicProvider colorMode={{ defaultValue: "dark" }} useCSSBaseline>
            <ToastProvider>{props.children}</ToastProvider>
        </TonicProvider>
    );
};
export default TrendUITheme;
