import { defaultGLinkPayload } from "@/components/genericlink/Const";
import { ScenarioType, ActivationType } from "@/types/enum";
import {
    backToEnterACFunc,
    SetGlinkPayloadFunc,
    IGLinkPayload,
    notSupportAccountFun,
    RemoveUserFunc,
    SetGenericLinkScenarioFunc,
    SetPartialActivatedFunc,
    SetSessionIdFunc,
    SetTokenAndOrderScenarioFunc,
    SetUserFunc,
    signOnWithAnotherAccountFunc,
    toGlinkEntranceFunc,
    UserHasAccountFunc,
    UserHasNoAccountFunc,
    SetFoundationAccountAndUserFunc,
    SetUpdateToV1Func,
    IFoundationData,
    SetFoundationDataFunc,
    setGlinkAgreementFunc,
    setMarketplacePayloadFunc,
    setOnBoardedCompanyFunc,
    SetAccountReadyFunc,
} from "@/types/redux/activation";
import {
    SET_TOKEN_AND_ORDER_SCENARIO,
    USER_HAS_NO_ACCOUNT,
    SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT,
    USER_HAS_ACCOUNT,
    SET_USER,
    SET_FOUNDATION_USER,
    REMOVE_USER,
    SET_PARTIAL_ACTIVATED,
    ENTER_AC,
    SET_SESSIONID,
    SET_GENERICLINK_SCENARIO,
    BACK_TO_ENTER_AC,
    SIGN_ON_WITH_ANOTHER_ACCOUNT,
    NOT_SUPPORT_ACCOUNT,
    TO_GLINK_ENTRANCE,
    ENTER_APIKEY,
    GLINK_C1_SIGN_UP,
    SET_UPDATETOV1,
    SET_FOUNDATION_DATA,
    SET_GLINK_AGREEMENT,
    SET_MARKETPLACE_PAYLOAD,
    SET_ON_BOARDED_COMPANY,
    SET_ACCOUNT_READY,
} from "../constants";
import { IGetUbtPayloadResponse } from "@/types/api/marketplace";

export const setTokenAndOrderScenario: SetTokenAndOrderScenarioFunc = (
    tokenData,
    scenarioData,
    foundationData,
) => {
    return {
        type: SET_TOKEN_AND_ORDER_SCENARIO,
        payload: {
            tokenData,
            scenarioData,
            foundationData,
        },
    };
};

export const setFoundationData: SetFoundationDataFunc = (
    foundationData: IFoundationData,
) => ({
    type: SET_FOUNDATION_DATA,
    payload: foundationData,
});

export const setUpdateToV1: SetUpdateToV1Func = (isUpdate: boolean) => ({
    type: SET_UPDATETOV1,
    payload: isUpdate,
});

export const setPartialActivated: SetPartialActivatedFunc = () => {
    return {
        type: SET_PARTIAL_ACTIVATED,
    };
};

export const userHasAccount: UserHasAccountFunc = () => ({
    type: USER_HAS_ACCOUNT,
});

export const userHasNoAccount: UserHasNoAccountFunc = () => ({
    type: USER_HAS_NO_ACCOUNT,
});
export const signInWithAnotherFoundationAccount: UserHasNoAccountFunc = () => ({
    type: SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT,
});

export const setUser: SetUserFunc = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setFoundationAccountAndUser: SetFoundationAccountAndUserFunc = (
    foundation,
    user,
) => ({
    type: SET_FOUNDATION_USER,
    payload: {
        foundation,
        user,
    },
});

export const setAccountReady: SetAccountReadyFunc = () => ({
    type: SET_ACCOUNT_READY,
    payload: true,
});

export const removeUser: RemoveUserFunc = () => ({
    type: REMOVE_USER,
});

export const setGlinkPayload: SetGlinkPayloadFunc = (payload) => ({
    type: ENTER_AC,
    payload: { ...payload },
});

export const setGlinkPayloadForApikey: SetGlinkPayloadFunc = (payload) => ({
    type: ENTER_APIKEY,
    payload: { ...payload },
});

export const setGenericLinkScenario: SetGenericLinkScenarioFunc = () => ({
    type: SET_GENERICLINK_SCENARIO,
    payload: {
        scenarioData: {
            scenarioType: ScenarioType.GenericLink,
            partialActivated: false,
        },
        glinkPayload: { ...defaultGLinkPayload },
    },
});

export const setSessionId: SetSessionIdFunc = (sessionId: string) => ({
    type: SET_SESSIONID,
    payload: sessionId,
});

export const notSupportAccount: notSupportAccountFun = (gLinkPayload: IGLinkPayload) => ({
    type: NOT_SUPPORT_ACCOUNT,
    payload: { ...gLinkPayload },
});

export const backToEnterAC: backToEnterACFunc = () => ({
    type: BACK_TO_ENTER_AC,
    payload: {
        scenarioData: {
            scenarioType: ScenarioType.GenericLink,
            partialActivated: false,
        },
    },
});

export const toGlinkEntrance: toGlinkEntranceFunc = (payload: IGLinkPayload) => ({
    type: TO_GLINK_ENTRANCE,
    payload,
});

export const signOnWithAnotherAccount: signOnWithAnotherAccountFunc = () => ({
    type: SIGN_ON_WITH_ANOTHER_ACCOUNT,
    payload: {
        scenarioData: {
            scenarioType: ScenarioType.GenericLink,
            partialActivated: true,
        },
        gLinkPayload: {
            ...defaultGLinkPayload,
            isEligible: true,
            isRegistered: true,
            hasAccount: true,
            activationType: ActivationType.Account,
        },
    },
});
export const glinkC1SignUpNew: UserHasNoAccountFunc = () => ({
    type: GLINK_C1_SIGN_UP,
});
export const setGlinkAgreement: setGlinkAgreementFunc = (payload) => ({
    type: SET_GLINK_AGREEMENT,
    payload: { ...payload },
});
export const setMarketplacePayload: setMarketplacePayloadFunc = (
    data: IGetUbtPayloadResponse,
) => ({
    type: SET_MARKETPLACE_PAYLOAD,
    payload: data,
});
export const setOnBoardedCompany: setOnBoardedCompanyFunc = (
    data: number | null | undefined,
) => ({
    type: SET_ON_BOARDED_COMPANY,
    payload: data,
});
