import { Text, theme, Stack } from "@tonic-ui/react";
import Trend from "@/assets/img/trend.svg";
import { FormattedMessage } from "react-intl";

export default function Banner({ title }: { title?: string }) {
    return (
        <header>
            <Stack gap="3x" direction="row" px="6x" h={64} py={theme.sizes["7h"]} backgroundColor={theme.colors["gray:90"]}>
                <img src={Trend} alt="trend micro icon" width={32} height={32} />
                <Text
                    size="2xl"
                    color="white:primary"
                    fontWeight="light"
                >
                    {title || (
                        <FormattedMessage defaultMessage="Trend Vision One™ | License Activation Service" />
                    )}
                </Text>
            </Stack>
        </header>
    );
}
