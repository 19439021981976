import axios, { AxiosInstance } from "axios";
import { v4 as uuidv4 } from "uuid";
import { axiosInstanceWithAdditionHeaders } from "@/utils/helper";

const baseURL = process.env.REACT_APP_API_PATH;

// token hash header for authentication
const tokenHashHeader = {
    tokenHash: "",
    activationToken: "",
};

export const setTokenHashForApi = (hash: string): void => {
    tokenHashHeader.tokenHash = hash;
};

export const setActivationTokenForApi = (token: string): void => {
    tokenHashHeader.activationToken = token;
};

export const instance = axios.create({
    baseURL,
    headers: {
        "pls-application-name": "activation-web-frontend",
        "content-type": "application/json",
    },
});

instance.interceptors.response.use(function (res) {
    return res.data;
});

/**
 * API with token
 * this wrapper will add "tokenHash" header
 */
export const activationApi: AxiosInstance = axiosInstanceWithAdditionHeaders(
    instance,
    () => ({
        ...tokenHashHeader,
        "pls-trace-id": uuidv4(), // generate unique id per request
    }),
);

export const genericLinkApi: AxiosInstance = axiosInstanceWithAdditionHeaders(
    instance,
    () => ({
        "pls-trace-id": uuidv4(), // generate unique id per request
    }),
);

/**
 * API with auth
 * this wrapper will add "Authorization: Bearer" header
 */
export const activationApiWithJWT = (jwtToken: string): AxiosInstance =>
    axiosInstanceWithAdditionHeaders(instance, {
        ...tokenHashHeader,
        "pls-trace-id": uuidv4(), // generate unique id per request
        Authorization: "Bearer " + jwtToken,
    });

export const genericLinkApiWithJWT = (jwtToken: string): AxiosInstance =>
    axiosInstanceWithAdditionHeaders(instance, {
        "pls-trace-id": uuidv4(), // generate unique id per request
        Authorization: "Bearer " + jwtToken,
    });
