import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { Text, Stack } from "@tonic-ui/react";
import { FormattedMessage } from "react-intl";
import warningImage from "@/assets/svg/image_warning.svg";
import useErrorMessage, { ErrorCode } from "@/hooks/error/useErrorMessage";
import { postKnowUserUBTLog, postUnknowUserUBTLog } from "@/api/ubt";
import { useContext, useEffect } from "react";
import useCommon from "@/hooks/useCommon";
import { ActivationType, PortalLink, ScenarioType } from "@/types/enum";
import { RootStore } from "@/redux/store";
import { RouteChildrenProps } from "react-router-dom";
import { UBTLoggingService } from "@/ubt/ubt";
import { IGLinkPayload } from "@/types/redux/activation";
import ErrorLog from "@/components/common/ErrorLog";
import ImportHint from "@/components/exporting/ImportHint";
import { frontendConfigContext } from "@/Wrapper/FrontendConfig";

export default function ErrorPage({
    match,
    location,
}: RouteChildrenProps<
    { code: ErrorCode },
    { ticket?: string; log?: string } | undefined
>): JSX.Element {
    const errorMessage = useErrorMessage();
    const errorCode = match?.params.code as ErrorCode;
    const error = errorMessage[errorCode] || errorMessage.EXA0000001;
    const { title, description, Contact, changePage, image } = error;

    // ticket id
    const common = useCommon();
    const trend = common[PortalLink.TrendMicroSupport];
    const queryObj = new URLSearchParams(location.search);
    const errorTicket = queryObj.get("ticket");
    const importHint = !!queryObj.get("importHint");

    // log
    const logCode = errorMessage[errorCode] ? errorCode : "EXA0000001";
    const scenarioType = useSelector<RootStore>(
        (state) => state.activation?.scenario?.scenarioType,
    );
    const gLinkPayload = useSelector<RootStore>(
        (state) => state.activation?.gLinkPayload,
    ) as IGLinkPayload;
    const loginId = useSelector<RootStore>((state) => state.activation?.user?.loginID);
    const jwt = useSelector<RootStore>((state) => state.activation?.user?.jwt);
    const log = location?.state?.log;

    useEffect((): void => {
        if (scenarioType === ScenarioType.GenericLink) {
            if (errorCode !== "C1_LICENSE_NOT_ELIGIBLE") {
                // "C1_LICENSE_NOT_ELIGIBLE" is recorded in previous page
                UBTLoggingService("Glink", "General", "Error", {
                    ac: gLinkPayload?.ac,
                    pid: gLinkPayload?.productId,
                    accountId: (loginId as string) || undefined,
                    errorCode,
                    activationType:
                        gLinkPayload?.activationType || ActivationType.ActivationCode,
                });
            }
            return;
        }

        if (!loginId) {
            postUnknowUserUBTLog({
                activity: "80",
                eventName: "link_activation_land_on_error_page",
                errorCode: logCode,
            });
            return;
        }

        postKnowUserUBTLog(jwt as string, {
            loginId: loginId as string,
            activity: "80",
            eventName: "link_activation_land_on_error_page",
            errorCode: logCode,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // fetch import tool url
    const {
        airgap: { importToolUrl },
    } = useContext(frontendConfigContext);

    // Check whether need to change page
    if (changePage) return <>{changePage}</>;

    return (
        <div
            css={css`
                    text-align: center;
                    min-height: calc(100vh - 64px - 84px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 24px 24px 48px 24px;
                `}
        >
            {image || <img src={warningImage} alt="Not Support" />}
            <Stack
                mt="3x"
                spacing="1rem"
                alignItems="center"
                textAlign="center"
            >
                <Stack fontSize="sm" maxWidth={600} gap="2x">
                    <Text size="2xl">{title}</Text>
                    <Text>{description}</Text>
                    {Contact && !errorTicket && <Text><Contact /></Text>}
                    {!errorTicket && (
                        <Text fontSize="sm" color="white:secondary">
                            <FormattedMessage defaultMessage="Error code:" />{" "}
                            {errorCode}
                        </Text>
                    )}
                    {errorTicket && (
                        <Stack>
                            <Text >
                                <FormattedMessage
                                    defaultMessage="Please contact <link></link> and provide following error code and ticket ID."
                                    values={{
                                        link: () => (
                                            <a
                                                rel="noreferrer"
                                                target="_blank"
                                                data-test="link_go_trend_contact_support"
                                                data-track="link_activation_error_go_trend_contact_support"
                                                className="u-link-color"
                                                href={trend.link}
                                            >
                                                {trend.name}
                                            </a>
                                        ),
                                    }}
                                />
                            </Text>
                            <Stack
                                backgroundColor="gray:90"
                                my="4x"
                                p="4x"
                                gap="2x"
                            >
                                <Text fontSize="md" color="white:primary">
                                    <FormattedMessage defaultMessage="Ticket ID" />
                                </Text>
                                <Text fontSize="xl" color="white:primary" pb="2x">
                                    {errorTicket}
                                </Text>
                                <Text fontSize="sm" color="white:secondary">
                                    <FormattedMessage defaultMessage="Error code:" />{" "}
                                    {errorCode}
                                </Text>
                            </Stack>
                        </Stack>
                    )}
                    {log && (
                        <ErrorLog
                            css={css`
                                        margin-bottom: 24px;
                                    `}
                            title="Detailed message"
                            log={log}
                        />
                    )}
                    {importHint && (
                        <Stack
                            mt="3x"
                            alignItems="center"
                        >
                            <ImportHint
                                css={css`
                                            max-width: 400px;
                                        `}
                                importToolUrl={importToolUrl}
                            />
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </div>
    );
}
