import { css } from "@emotion/react";
import classnames from "classnames";
import { HTMLProps } from "react";

interface IBodyProps extends HTMLProps<HTMLDivElement> {
    background?: boolean;
}
export default function Body({
    background = false,
    className = "",
    ...rest
}: IBodyProps) {
    return (
        <div
            css={css`
                flex-grow: 1;
                position: relative;
            `}
            className={classnames(background && "dark-mode-img", className)}
            {...rest}></div>
    );
}
