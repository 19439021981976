import { frontendConfigContext } from "@/Wrapper/FrontendConfig";
import { PortalLink } from "@/types/enum";
import { useContext } from "react";
import { useIntl } from "react-intl";

interface ILinkReference {
    name: string;
    link: string;
}

const useCommon = (): Record<PortalLink, ILinkReference> => {
    const intl = useIntl();
    const {
        frontendUrl: { clpDashboard },
    } = useContext(frontendConfigContext);
    return {
        [PortalLink.GoogleChrome]: {
            name: intl.formatMessage({
                defaultMessage: "Google Chrome™",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://www.google.com/chrome/",
            }),
        },
        [PortalLink.MozillaFirefox]: {
            name: intl.formatMessage({
                defaultMessage: "Mozilla® Firefox®",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://www.mozilla.org/en-US/exp/",
            }),
        },
        [PortalLink.MicrosoftEdge]: {
            name: intl.formatMessage({
                defaultMessage: "Microsoft Edge",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://www.microsoft.com/en-us/edge",
            }),
        },
        [PortalLink.TrendMicroSupport]: {
            name: intl.formatMessage({
                defaultMessage: "Trend Micro Support",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://success.trendmicro.com/contact-support",
            }),
        },
        [PortalLink.GetMoreInformation]: {
            name: intl.formatMessage({
                defaultMessage: "request more information",
            }),
            link: intl.formatMessage({
                defaultMessage:
                    "https://www.trendmicro.com/en_us/business/get-info-form.html",
            }),
        },
        [PortalLink.GlobalTrendMicroAgreement]: {
            name: intl.formatMessage({
                defaultMessage: "Global",
            }),
            link: "https://www.trendmicro.com/en_us/about/legal.html#t4",
        },
        [PortalLink.JapanTrendMicroAgreement]: {
            name: intl.formatMessage({
                defaultMessage: "Japan",
            }),
            link: "https://www.trendmicro.com/ja_jp/about/legal/eula.html",
        },
        [PortalLink.GlobalPrivacyNotice]: {
            name: intl.formatMessage({
                defaultMessage: "Global",
            }),
            link:
                "https://www.trendmicro.com/en_us/about/trust-center/privacy/notice.html",
        },
        [PortalLink.JapanPrivacyNotice]: {
            name: intl.formatMessage({
                defaultMessage: "Japan",
            }),
            link:
                "https://www.trendmicro.com/ja_jp/about/legal/privacy-policy/product.html",
        },
        [PortalLink.DataCollectionNotice]: {
            name: intl.formatMessage({
                defaultMessage: "Data Collection Notice",
            }),
            link: intl.formatMessage({
                defaultMessage:
                    "https://success.trendmicro.com/data-collection-disclosure",
            }),
        },
        [PortalLink.TrendMicro]: {
            name: intl.formatMessage({
                defaultMessage: "Trend Micro",
            }),
            link: intl.formatMessage({
                defaultMessage: "http://www.trendmicro.com/",
            }),
        },
        [PortalLink.LegalPoliciesPrivacy]: {
            name: intl.formatMessage({
                defaultMessage: "Legal Policies & Privacy",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://www.trendmicro.com/en_us/about/legal.html",
            }),
        },
        [PortalLink.ContactUs]: {
            name: intl.formatMessage({
                defaultMessage: "Contact Us",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://www.trendmicro.com/en_us/contact.html",
            }),
        },
        [PortalLink.Help]: {
            name: intl.formatMessage({
                defaultMessage: "Help",
            }),
            link: intl.formatMessage({
                defaultMessage: "https://success.trendmicro.com/contact-support",
            }),
        },
        [PortalLink.CustomerLicensingPortal]: {
            name: intl.formatMessage({
                defaultMessage: "Customer Licensing Portal",
            }),
            link: clpDashboard,
        },
        [PortalLink.TrendMicroVisionOne]: {
            name: intl.formatMessage({
                defaultMessage: "Trend Vision One™",
            }),
            link: "",
        },
        [PortalLink.TrendMicroCloudOne]: {
            name: intl.formatMessage({
                defaultMessage: "Trend Cloud One™",
            }),
            link: "",
        },
        [PortalLink.CreditConversionOnlineHelp]: {
            name: intl.formatMessage({
                defaultMessage: "Learn more",
            }),
            link: intl.formatMessage({
                defaultMessage:
                    "https://docs.trendmicro.com/en-us/enterprise/trend-micro-xdr-help/CreditConversion",
            }),
        },
        [PortalLink.GenericLinkSupportProduct]: {
            name: intl.formatMessage({
                defaultMessage: "View supported Trend Micro products",
            }),
            link: intl.formatMessage({
                defaultMessage:
                    "https://docs.trendmicro.com/en-us/enterprise/trend-micro-xdr-help/ProdSppt4BasicAccessLink",
            }),
        },
        [PortalLink.PostUpdateTaskLink]: {
            name: intl.formatMessage({
                defaultMessage: "Post-Update Tasks",
            }),
            link: intl.formatMessage({
                defaultMessage:
                    "https://docs.trendmicro.com/en-us/enterprise/trend-vision-one/esofoundation/gettingstartedv1es/updatesolutionsintro/postupdate.aspx",
            }),
        },
    };
};

export default useCommon;
