/**
 * ref:
 * https://trendmicro-frontend.github.io/styled-ui/toast
 */
import { useCallback } from "react";
import { useToast as useTonicToast, Box, Toast } from "@tonic-ui/react";

type Position =
    | "top"
    | "top-left"
    | "top-right"
    | "bottom"
    | "bottom-left"
    | "bottom-right";

type Appearance = "none" | "success" | "info" | "warning" | "error";

interface NotifyFunc {
    (params: {
        position?: Position;
        style?: Record<string, string | number>;
        duration?: number;
        appearance?: Appearance;
        message: string;
    }): void;
}

interface IUseToastReturn {
    notify: NotifyFunc;
}

const useToast = (): IUseToastReturn => {
    const toast = useTonicToast();
    const notify: NotifyFunc = useCallback(
        ({
            position = "bottom-right",
            style = {
                transform: "translateY(-60px)",
            },
            duration = 5000,
            appearance = "success",
            message = "",
        }) => {
            toast(
                ({
                    onClose,
                    placement,
                }: {
                    onClose: () => void;
                    placement: Position;
                }) => {
                    const styleProps = {
                        "top-left": { mt: "2x", mx: "4x" },
                        top: { mt: "2x", mx: "4x" },
                        "top-right": { mt: "2x", mx: "4x" },
                        "bottom-left": { mb: "2x", mx: "4x" },
                        bottom: { mb: "2x", mx: "4x" },
                        "bottom-right": { mb: "2x", mx: "4x" },
                    }[placement];
                    return (
                        <Box
                            {...styleProps}
                            {...style}
                            fontSize="sm"
                            lineHeight="sm"
                            textAlign="left"
                            width={320}
                        >
                            <Toast appearance={appearance} isClosable onClose={onClose}>
                                {message}
                            </Toast>
                        </Box>
                    );
                },
                {
                    placement: position,
                    duration,
                },
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    return {
        notify,
    };
};

export default useToast;
