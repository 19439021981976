import ActivationSuccessful from "@/assets/svg/activateSuccess.svg";
import { useContext } from "react";
import { Text as TonicText, Box } from "@tonic-ui/react";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { frontendConfigContext } from "@/Wrapper/FrontendConfig";

// !! Since tonic ui v1, <Text/> is no longer default inline-block
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Text = (props: any) => <TonicText display="inline-block" {...props} />;

export const FullyActivated = () => {
    const {
        frontendUrl: { visionOnePortal },
    } = useContext(frontendConfigContext);

    return (
        <div
            css={css`
                text-align: center;
                width: 100%;
                min-height: calc(100vh - 64px);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 52px 10px;
            `}
        >
            <div>
                <div>
                    <img src={ActivationSuccessful} alt="Activation Successful" />
                </div>
                <Box mt={20}>
                    <Text fontSize="16px">
                        <FormattedMessage defaultMessage="Your licenses of Trend Micro products renewed or registered successfully." />
                    </Text>
                </Box>
                <Box mt={16} mb={24}>
                    <Text fontSize="14px" color="white:secondary">
                        <FormattedMessage
                            defaultMessage="Sign in to <link>Trend Vision One</link> and view your licenses in Administration > License Information."
                            values={{
                                link: (txt: string) => (
                                    <a href={visionOnePortal}>{txt}</a>
                                ),
                            }}
                        />
                    </Text>
                </Box>
                <div
                    css={css`
                        > form:not(:last-child) {
                            margin-bottom: 24px;
                        }
                    `}
                ></div>
            </div>
        </div>
    );
};

const PreCheck = {
    FullyActivated,
};

export default PreCheck;
