import { AxiosError } from "axios";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

type IApiErrorResponse = AxiosError<{ ErrorCode: string }>;
type IApiErrorLogResponse = AxiosError<{ Log: string }>;

interface ErrorHandlerFunc {
    (
        error?: IApiErrorResponse | string | unknown,
        additionProps?: { ticketId: string },
    ): void;
}

const useGoErrorPage = (): ErrorHandlerFunc => {
    const history = useHistory();

    const errorHandler: ErrorHandlerFunc = useCallback(
        (e, props) => {
            const errorCode =
                (e as IApiErrorResponse)?.response?.data?.ErrorCode ||
                (e as string) ||
                "EXA0000001";
            const isExportTool = history.location.pathname.includes("export");
            const search = new URLSearchParams();
            if (props?.ticketId) search.append("ticket", props.ticketId);
            if (isExportTool) search.append("importHint", "true");

            const state = {
                log: (e as IApiErrorLogResponse)?.response?.data?.Log || "",
                ticket: props?.ticketId || "",
            };

            history.push({
                pathname: `/error/${errorCode}`,
                search: `?${search.toString()}`, // query string
                state,
            });
        },
        [history],
    );
    return errorHandler;
};

export default useGoErrorPage;
