import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import Banner from "@/components/layout/Banner";
import Footer from "@/components/layout/Footer";
import { RootStore } from "@/redux/store";
import { IScenarioData } from "@/types/redux/activation";
import { ScenarioType } from "@/types/enum";
import { ComponentType } from "react";
import ErrorBoundary from "@/Wrapper/ErrorBoundary";

export default function withLayout<P extends object>(
    Component: ComponentType<P>,
    options: {
        footer?: boolean;
        title?: string;
    } = {
        footer: true,
    },
) {
    return function Layout(props: P) {
        const user = useSelector<RootStore>((state) => state.activation.user);
        const scenario = useSelector<RootStore, IScenarioData | null>(
            (state) => state.activation.scenario,
        );

        const { footer, title } = options;
        const showFooter =
            footer && !user && scenario?.scenarioType !== ScenarioType.CloudOneOnly;

        return (
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    min-width: 480px;
                    min-height: 100vh;
                `}>
                <Banner title={title} />
                <ErrorBoundary>
                    <Component {...props} />
                </ErrorBoundary>
                {showFooter && <Footer />}
            </div>
        );
    };
}
