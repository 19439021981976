import { activationApi, activationApiWithJWT } from "@/api/setup";
import { IPostUBTLogBody, IPostUserUBTLogBody } from "@/types/api/ubt";

export const postKnowUserUBTLog = (jwt: string, body: IPostUBTLogBody): Promise<void> =>
    process.env.REACT_APP_AIR_GAP === "Global"
        ? activationApiWithJWT(jwt).post("/ubt", body)
        : Promise.resolve();

export const postUnknowUserUBTLog = (body: IPostUBTLogBody): Promise<void> =>
    process.env.REACT_APP_AIR_GAP === "Global"
        ? activationApi.post("/ubt", body)
        : Promise.resolve();

export const postUserUBTLog = (body: IPostUserUBTLogBody): Promise<void> =>
    process.env.REACT_APP_AIR_GAP === "Global"
        ? activationApi.post("/ubt", body)
        : Promise.resolve();
