export const SET_TOKEN_AND_ORDER_SCENARIO = "SET_TOKEN_AND_ORDER_SCENARIO";
export const SET_FOUNDATION_DATA = "SET_FOUNDATION_DATA";
export const SET_PARTIAL_ACTIVATED = "SET_PARTIAL_ACTIVATED";
export const USER_HAS_ACCOUNT = "USER_HAS_ACCOUNT";
export const USER_HAS_NO_ACCOUNT = "USER_HAS_NO_ACCOUNT";
export const SET_USER = "SET_USER";
export const SET_FOUNDATION_USER = "SET_FOUNDATION_USER";
export const SET_ACCOUNT_READY = "SET_ACCOUNT_READY";
export const REMOVE_USER = "REMOVE_USER";
export const ENTER_AC = "ENTER_AC";
export const ENTER_APIKEY = "ENTER_APIKEY";
export const SET_GENERICLINK_SCENARIO = "SET_GENERICLINK_SCENARIO";
export const SET_SESSIONID = "SET_SESSIONID";
export const NOT_SUPPORT_ACCOUNT = "NOT_SUPPORT_ACCOUNT";
export const BACK_TO_ENTER_AC = "BACK_TO_ENTER_AC";
export const SIGN_ON_WITH_ANOTHER_ACCOUNT = "SIGN_ON_WITH_ANOTHER_ACCOUNT";
export const TO_GLINK_ENTRANCE = "TO_GLINK_ENTRANCE";
export const GLINK_C1_SIGN_UP = "GLINK_C1_SIGN_UP";
export const SET_UPDATETOV1 = "SET_UPDATETOV1";
export const SET_GLINK_AGREEMENT = "SET_GLINK_AGREEMENT";
export const SET_MAPPING_ACCOUNT_ERROR = "SET_MAPPING_ACCOUNT_ERROR";
export const SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT =
    "SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT";
export const SET_MARKETPLACE_PAYLOAD = "SET_MARKETPLACE_PAYLOAD";
export const SET_ON_BOARDED_COMPANY = "SET_ON_BOARDED_COMPANY";
