/* eslint-disable no-case-declarations */
import {
    SET_TOKEN_AND_ORDER_SCENARIO,
    SET_FOUNDATION_DATA,
    SET_PARTIAL_ACTIVATED,
    USER_HAS_ACCOUNT,
    USER_HAS_NO_ACCOUNT,
    SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT,
    SET_USER,
    SET_FOUNDATION_USER,
    REMOVE_USER,
    ENTER_AC,
    SET_SESSIONID,
    SET_GENERICLINK_SCENARIO,
    NOT_SUPPORT_ACCOUNT,
    BACK_TO_ENTER_AC,
    SIGN_ON_WITH_ANOTHER_ACCOUNT,
    TO_GLINK_ENTRANCE,
    ENTER_APIKEY,
    GLINK_C1_SIGN_UP,
    SET_UPDATETOV1,
    SET_GLINK_AGREEMENT,
    SET_MARKETPLACE_PAYLOAD,
    SET_ON_BOARDED_COMPANY,
    SET_ACCOUNT_READY,
} from "../constants";
import {
    ISetGlinkPayloadAction,
    IActivationState,
    IRemoveUserAction,
    ISetPartialActivatedAction,
    ISetSessionIdAction,
    ISetTokenAndOrderScenarioAction,
    ISetUserAction,
    IUserHasAccountAction,
    IUserHasNoAccountAction,
    ISetGenericLinkScenarioAction,
    IBackToEnterACAction,
    ISignOnWithAnotherAccountAction,
    INotSupportAccountAction,
    IToGlinkEntranceAction,
    ISetFoundationAccountAction,
    IUpdateToV1Action,
    IFoundationData,
    ISetFoundationDataAction,
    ISetGlinkAgreementAction,
    ISetMarketplacePayloadAction,
    ISetOnBoardedCompanyAction,
    ISetAccountReadyAction,
} from "@/types/redux/activation";
import { ScenarioType } from "@/types/enum";
import { defaultGLinkPayload } from "@/components/genericlink/Const";

const initialState: IActivationState = {
    // Only states that affect activation flow should be in Redux state
    token: null,
    scenario: null,
    hasAccount: true,
    user: null,
    gLinkPayload: null,
    sessionId: null,
    glinkC1SingUp: false,
    foundationData: null,
    foundationAccount: null,
    glinkAgreement: false,
    marketplacePayload: null,
    showFDEula: false,
};

type ActivationActions =
    | ISetTokenAndOrderScenarioAction
    | ISetPartialActivatedAction
    | IUserHasAccountAction
    | IUserHasNoAccountAction
    | ISetUserAction
    | IRemoveUserAction
    | ISetGlinkPayloadAction
    | ISetMarketplacePayloadAction;

export default function activationFlowReducer(
    state = initialState,
    action: ActivationActions,
): IActivationState {
    switch (action.type) {
        case TO_GLINK_ENTRANCE:
            const t = action as IToGlinkEntranceAction;
            return {
                ...state,
                gLinkPayload: { ...t.payload },
            };
        case NOT_SUPPORT_ACCOUNT:
            const n = action as INotSupportAccountAction;
            return { ...state, gLinkPayload: { ...n.payload } };
        case BACK_TO_ENTER_AC:
            const b = action as IBackToEnterACAction;
            return {
                ...state,
                scenario: { ...b.payload.scenarioData },
                user: null,
                gLinkPayload: { ...defaultGLinkPayload },
            };
        case SIGN_ON_WITH_ANOTHER_ACCOUNT:
            const c = action as ISignOnWithAnotherAccountAction;
            return {
                ...state,
                scenario: { ...c.payload.scenarioData },
                user: null,
                gLinkPayload: { ...c.payload.gLinkPayload },
            };
        case ENTER_AC:
            const a = action as ISetGlinkPayloadAction;
            return {
                ...state,
                scenario: {
                    scenarioType: ScenarioType.GenericLink,
                    partialActivated: a.payload.isRegistered && a.payload.hasAccount,
                },
                hasAccount: true,
                gLinkPayload: a.payload,
            };
        case ENTER_APIKEY:
            const d = action as ISetGlinkPayloadAction;
            return {
                ...state,
                scenario: {
                    scenarioType: ScenarioType.GenericLink,
                    partialActivated: d.payload.isRegistered && d.payload.hasAccount,
                },
                hasAccount: !!(d.payload.accounts && d.payload.accounts.length),
                gLinkPayload: d.payload,
            };
        case SET_GENERICLINK_SCENARIO: {
            const a = action as ISetGenericLinkScenarioAction;
            const { scenarioData, glinkPayload } = a.payload;
            return {
                ...state,
                scenario: scenarioData,
                gLinkPayload: glinkPayload,
            };
        }
        case SET_TOKEN_AND_ORDER_SCENARIO: {
            const a = action as ISetTokenAndOrderScenarioAction;
            const { tokenData, scenarioData, foundationData } = a.payload;
            return {
                ...state,
                token: tokenData,
                scenario: scenarioData,
                foundationData,
            };
        }
        case SET_FOUNDATION_DATA: {
            const a = action as ISetFoundationDataAction;
            let resetGlinkPayload = { ...defaultGLinkPayload };
            if (a.payload.gLinkPayload) {
                resetGlinkPayload = {
                    ...defaultGLinkPayload,
                    activationType: a.payload.gLinkPayload.activationType,
                };
                return {
                    ...state,
                    foundationData: a.payload,
                    gLinkPayload: resetGlinkPayload,
                };
            }
            return {
                ...state,
                foundationData: a.payload,
            };
        }
        case SET_UPDATETOV1: {
            const a = action as IUpdateToV1Action;
            return {
                ...state,
                foundationData: {
                    ...state.foundationData,
                    upgradeToV1ES: a.payload,
                } as IFoundationData,
            };
        }
        case SET_PARTIAL_ACTIVATED:
            return {
                ...state,
                scenario: {
                    scenarioType: state.scenario?.scenarioType as ScenarioType,
                    partialActivated: true,
                },
            };
        case USER_HAS_ACCOUNT:
            return {
                ...state,
                hasAccount: true,
            };
        case USER_HAS_NO_ACCOUNT:
            return {
                ...state,
                hasAccount: false,
            };
        case SIGN_ON_WITH_ANOTHER_FOUNDATION_ACCOUNT:
            return {
                ...state,
                user: null,
                foundationData: {} as IFoundationData,
                glinkAgreement: true,
            };
        case SET_USER: {
            const a = action as ISetUserAction;
            return {
                ...state,
                user: a.payload,
            };
        }
        case SET_FOUNDATION_USER: {
            const a = action as ISetFoundationAccountAction;
            return {
                ...state,
                foundationAccount: a.payload.foundation,
                user: a.payload.user,
            };
        }
        case SET_ACCOUNT_READY: {
            const a = action as ISetAccountReadyAction;
            return {
                ...state,
                foundationData: {
                    ...state.foundationData,
                    isAccountReady: a.payload,
                } as IFoundationData,
            };
        }
        case REMOVE_USER:
            return {
                ...state,
                user: null,
            };
        case SET_SESSIONID: {
            const a = action as ISetSessionIdAction;
            return {
                ...state,
                sessionId: a.payload,
            };
        }
        case GLINK_C1_SIGN_UP: {
            return {
                ...state,
                glinkC1SingUp: true,
            };
        }
        case SET_GLINK_AGREEMENT: {
            const b = action as ISetGlinkAgreementAction;
            return {
                ...state,
                glinkAgreement: b.payload.glinkAgreement,
                showFDEula: b.payload.showFDEula,
            };
        }
        case SET_MARKETPLACE_PAYLOAD: {
            const b = action as ISetMarketplacePayloadAction;
            return {
                ...state,
                marketplacePayload: {
                    ...state.marketplacePayload,
                    ubtPayload: b.payload,
                },
            };
        }
        case SET_ON_BOARDED_COMPANY: {
            const b = action as ISetOnBoardedCompanyAction;
            return {
                ...state,
                marketplacePayload: {
                    ...state.marketplacePayload,
                    companyUid: b.payload,
                },
            };
        }
        default:
            return state;
    }
}
