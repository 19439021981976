import { HTMLProps, ReactNode, useContext } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { frontendConfigContext } from "./FrontendConfig";

interface IReCaptchaProviderProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
}
export default function ReCaptchaV3Provider({ children }: IReCaptchaProviderProps) {
    const {
        recaptcha: { siteKeyV3 },
    } = useContext(frontendConfigContext);

    return (
        <>
            {siteKeyV3 ? (
                <GoogleReCaptchaProvider reCaptchaKey={siteKeyV3}>
                    {children}
                </GoogleReCaptchaProvider>
            ) : (
                <>{children}</>
            )}
        </>
    );
}
